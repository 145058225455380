import { PropsWithChildren } from "react";
import { classNames } from "@/helpers/tailwind-class";

interface BorderProps extends PropsWithChildren {
  borderSize: number;
}
export function GradientBorder({ children, borderSize }: BorderProps) {
  return (
    <BorderBase
      borderSize={borderSize}
      gradientClasses="bg-gradient-to-r from-teal-300 via-indigo-400 to-purple-600"
    >
      {children}
    </BorderBase>
  );
}

export function PlainBorder({ children, borderSize }: BorderProps) {
  return (
    <BorderBase borderSize={borderSize} gradientClasses="bg-gray-500">
      {children}
    </BorderBase>
  );
}

function BorderBase({
  children,
  borderSize,
  gradientClasses,
}: BorderProps & { gradientClasses: string }) {
  return (
    <div
      className={classNames(
        "box-border flex w-full items-center justify-center overflow-hidden rounded-md ",
        gradientClasses
      )}
      style={{ padding: borderSize }}
    >
      <div className="h-full w-full rounded-md focus:outline-none">{children}</div>
    </div>
  );
}
