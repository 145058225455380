import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, PropsWithChildren, ReactNode } from "react";

interface BaseModalProps extends Required<PropsWithChildren> {
  isOpen: boolean;
  handleClose: () => void;
  title: ReactNode;
}

/**
 * Modal component providing basic modal functionality.
 * Includes a title, and a body only
 */
export function BaseModal({ isOpen, handleClose, children, title }: BaseModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-screen items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-900 p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle
                  as="h3"
                  className="relative text-center text-2xl font-bold leading-10 text-neutral-50"
                >
                  {title}
                  <XMarkIcon
                    className="absolute right-0 top-0 ml-auto h-6 w-6 cursor-pointer rounded-full p-1 hover:bg-slate-700"
                    onClick={handleClose}
                  />
                </DialogTitle>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

interface StandardModalProps extends BaseModalProps {
  buttonText: string;
  buttonAction: () => void;
  title: string;
  buttonType: "primary" | "danger" | "info";
  buttonDisabled: boolean;
  showCancelButton?: boolean;
}

/**
 * Modal component providing standard format.
 * Includes a title, a body, and two buttons.
 */
export function StandardModal({
  isOpen,
  handleClose,
  buttonText,
  buttonAction,
  title,
  children,
  buttonType,
  buttonDisabled,
  showCancelButton = true,
}: StandardModalProps) {
  const buttonClasses = {
    primary: "btn btn-primary",
    danger: "btn btn-error",
    info: "btn btn-info",
  }[buttonType];

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose} title={title}>
      <div className="mt-8">{children}</div>

      <div className="mt-8 flex justify-center gap-8">
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-ghost btn-neutral inline-flex justify-center rounded-md px-8 py-2"
            onClick={handleClose}
            disabled={buttonDisabled}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          className={`inline-flex justify-center rounded-md px-8 py-2 ${buttonClasses}`}
          onClick={buttonAction}
          disabled={buttonDisabled}
        >
          {buttonText}
        </button>
      </div>
    </BaseModal>
  );
}
